import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { UserRole } from '../../types/enums';
interface SideBarProps {
    sideBarToggle: (index: number) => void;
}

const Sidebar: React.FC<SideBarProps> = ({ sideBarToggle }) => {
    const [items, setItems] = useState(['Merchant Cover']);
    useEffect(() => {
        const authData = JSON.parse(
            localStorage.getItem('authCheck') ?? 'null',
        );
        if (authData && authData.role === UserRole.Admin) {
            setItems([...items, 'Admin', 'Call Back Requests', 'Users - SME']);
        }
    }, []);

    const expand = false;
    return (
        <>
            <Navbar expand={expand} className={`bg-body-tertiary mb-3`}>
                <Container fluid className={styles.sidebar}>
                    <Navbar.Toggle
                        aria-controls={`offcanvasNavbar-expand-${expand}`}
                    />
                    <Navbar.Offcanvas
                        style={{ width: '250px' }}
                        className={`${styles.navbar}`}
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="start"
                    >
                        <Offcanvas.Header
                            closeButton
                            className={styles.titleHeader}
                        >
                            <Offcanvas.Title
                                id={`offcanvasNavbarLabel-expand-${expand}`}
                                className={styles.title}
                            >
                                Tools
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav>
                                {items?.map((item, index) => (
                                    <p
                                        className={styles.option}
                                        key={index}
                                        onClick={() => sideBarToggle(index)}
                                    >
                                        {item}
                                    </p>
                                ))}
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    );
};

export default Sidebar;
