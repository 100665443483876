// App.tsx
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import AdminLogin from './components/AdminLogin/AdminLogin';
import FormComponent from './components/AdminTool/AdminTool';
import Sidebar from './common/SideBar/Sidebar';
import MerchantCover from './components/MerchantCover/MerchantCover';
import CallBackRequests from './components/CallBackRequests/CallBackRequests';
import styles from './App.module.scss';
import SmeUsers from './components/SMEUsers/SmeUsers';

const App: React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [tool, setTool] = useState(0);
    useEffect(() => {
        const authData = JSON.parse(
            localStorage.getItem('authCheck') ?? 'null',
        );
        if (!authData || authData?.expireTime <= Date.now()) {
            localStorage.removeItem('AuthCheck');
            setIsLoggedIn(false);
        }
    }, []);

    const sideBarToggle = (index: number) => {
        setTool(index);
    };
    return (
        <div>
            <ToastContainer />
            {isLoggedIn ? (
                <>
                    <div className={styles.sidebar}>
                        <Sidebar sideBarToggle={sideBarToggle} />
                    </div>
                    {tool !== 0 ? (
                        tool === 1 ? (
                            <FormComponent />
                        ) : tool === 3 ? (<SmeUsers />) : (
                            <CallBackRequests />
                        )
                    ) : (
                        <MerchantCover />
                    )}
                </>
            ) : (
                <AdminLogin onLogin={() => setIsLoggedIn(true)} />
            )}
        </div>
    );
};

export default App;
